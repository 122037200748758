<template>
  <div class="container mt-5 mx-auto col-lg-4">
    <div class="card border-primary">
      <div class="card-header text-center">
        <h3><i class="fa fa-user"></i> Perfil de Usuario</h3>
      </div>
      <div class="card-body">        
        <p><strong>Usuario: </strong> {{usuario.usuario}}</p>
        <p><strong>Rol: </strong> {{usuario.rol}}</p>
        <p><strong>Repartición: </strong> {{usuario.reparticion}}</p>        
        <hr>
        <p><strong>Nro. Documento: </strong> {{usuario.dip}} </p>
        <p><strong>Nombre Completo: </strong> {{usuario.nombrecompleto}} </p>
      </div>
    </div>
     <Loading v-show="cargando" />   
  </div>   

</template>


<script>
import Loading from '@/components/Loading';
import moment from 'moment'

export default {
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
      usuario: {
        usuario:'',
        dip:'',
        nombrecompleto: '',
        rol:''
      }
    }
  },
  created () {
    this.listarDatos();
  },
  methods: {
    async listarDatos () {      
      this.cargando= true;
      try {
        let res = await this.axios.get(`/usuarioData`);
        this.usuario = res.data.contenido[0];  
        this.cargando = false;
      } catch (error) {
        console.log(error);
      }      
    },
    getDate : function (date) {
     return moment(date).format('YYYY-mm-DD');
    }
  }
}

</script>